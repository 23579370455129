import {SystemStyleObject} from '@chakra-ui/react';

const defaultProps = {
  variant: 'solid',
  size: 'md',
  colorScheme: 'primary',
};

const baseStyle: SystemStyleObject = {
  fontWeight: 300,
};

export const Button = {
  baseStyle,
  defaultProps,
};
