import {useState, useEffect} from 'react';

interface Props {
  isSubmitting: boolean;
  endpoint: string;
}
interface Response {
  title: string;
  msg: string;
}

export const useHandleRestriction = ({isSubmitting, endpoint}: Props) => {
  const [response, setResponse] = useState<Response>({
    title: '',
    msg: '',
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    setIsCompleted(false);
    if (endpoint !== '' && isSubmitting) {
      (async () => {
        setIsLoading(true);
        try {
          const resp = await fetch(endpoint);
          const data: Response = await resp.json();
          if (!resp.ok) {
            setIsError(true);
          }
          setResponse({
            title: data.title,
            msg: data.msg,
          });
        } catch (e: any) {
          setIsError(true);
          setResponse({
            title: 'Er is onbekende fout opgetreden',
            msg: 'Probeer het opnieuw of neem contact op.',
          });
        }
        setIsLoading(false);
        setIsCompleted(true);
      })();
    }
  }, [isSubmitting, endpoint]);

  return {
    response,
    isError,
    isLoading,
    isCompleted,
  };
};
