const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'normal',
};

const defaultProps = {
  size: 'xl',
};

const variants = {
  h1: {
    fontSize: '4xl',
    mb: 4,
  },
};

export const Heading = {
  baseStyle,
  defaultProps,
  variants,
};
