import {Box} from '@chakra-ui/react';
import React, {ReactNode} from 'react';
import {Main} from '../_shared/Main';

interface Props {
  children: ReactNode;
}
export const ContentContainer = ({children}: Props) => {
  return (
    <Main>
      <Box py={4}>{children}</Box>
    </Main>
  );
};
