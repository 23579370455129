import {Box, Flex, Image /*Select*/} from '@chakra-ui/react';
import React from 'react';
import {Main} from '../_shared/Main';
import logo from '../../assets/images/logo_klm_health_services.jpg';
//import {useTranslation} from 'react-i18next';
//import {languages} from '../../providers/i18n/i18n';

export const Header = () => {
  //const {i18n} = useTranslation();
  return (
    <Box
      as="header"
      py={4}
      sx={{
        borderBottom: '1px solid #c2deea',
      }}>
      <Main>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection={['column', 'row']}>
          <Image src={logo} h="53px" w="208px" />
          <Flex alignItems="center" justifyContent="center" mt={[2, 0]}>
            {/*<Select
              width="auto"
              cursor="pointer"
              boxShadow="0px 0px 20px 5px rgba(0, 39, 118, 0.13)"
              borderRadius="11px"
              iconColor="secondary"
              backgroundColor="white"
              border="none"
              variant="filled"
              value={i18n.language}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                i18n.changeLanguage(e.currentTarget.value)
              }>
              {languages.map((lng) => (
                <option key={lng} value={lng}>
                  {lng.toUpperCase()}
                </option>
              ))}
              </Select>*/}
          </Flex>
        </Flex>
      </Main>
    </Box>
  );
};
