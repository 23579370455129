import {extendTheme} from '@chakra-ui/react';
import {createBreakpoints} from '@chakra-ui/theme-tools';
import {components} from '../components';
import {colors} from './colors';

export const fonts = {
  heading: `'Noa LT Std', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `'Noa LT Std', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `'Menlo', monospace`,
};

export const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
}); // ["480px", "768px", "992px", "1280px"]

export const sizes = {
  container: {
    xl: '1120px',
  },
};

export const theme = extendTheme({
  fonts,
  components,
  colors,
  breakpoints,
  sizes,
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Noa LT Std',
        color: colors.blue['500'],
        fontWeight: 300,
      },
      p: {
        mb: 4,
      },
      a: {
        textDecoration: 'underline',
      },
      fieldset: {
        p: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.blue['500'],
        borderRadius: 4,
      },
    }),
  },
});

export type DefaultTheme = typeof theme;
