export type Colors = typeof colors;

const paletteDefault = {
  DarkBlue: '#005B83',
  LightBlue: '#00A1DE',
  Grey: '#888888',
};

export const colors = {
  //primary: paletteDefault.DarkBlue,
  secondary: paletteDefault.LightBlue,
  faqBackground: 'white',
  primary: {
    '50': '#fff3e5',
    '100': '#ffdab3',
    '200': '#f8bd86',
    '300': '#e59f67',
    '400': '#d58044',
    '500': '#e37222',
    '600': '#984a1a',
    '700': '#792e07',
    '800': '#4d1800',
    '900': '#1a0700',
  },
  blue: {
    '50': '#e5fbff',
    '100': '#b3efff',
    '200': '#80e1ff',
    '300': '#53ccf9',
    '400': '#2ab2ee',
    '500': '#005B83',
    '600': '#0674ac',
    '700': '#005080',
    '800': '#002d4d',
    '900': '#000e1a',
  },
  grey: {
    '50': '#f7eeee',
    '100': '#e2d0d0',
    '200': '#c7b8b8',
    '300': '#a6a6a6',
    '400': '#8c8c8c',
    '500': '#888888',
    '600': '#595959',
    '700': '#473838',
    '800': '#2f1d1d',
    '900': '#110808',
  },
};
