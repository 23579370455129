import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  title: string;
  description: string;
  isError: boolean;
}
export const AlertBox = ({title, description, isError}: Props) => {
  return (
    <Box mt={4}>
      <Alert
        flexDirection="column"
        status={isError ? 'error' : 'success'}
        textAlign="center">
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {title}
        </AlertTitle>
        <AlertDescription maxWidth="sm">{description}</AlertDescription>
      </Alert>
    </Box>
  );
};
