import {ChakraProvider} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {Content} from './components/Content';
import {ContentTrv} from './components/Content';
import {Fonts} from './components/Fonts';
import {Footer} from './components/Footer';
import {Header} from './components/Header';
import {defaultTheme} from './theme/themes';
import {Helmet} from 'react-helmet';
import {Environment} from './types';

export const App = () => {
  const [environment, setEnvironment] = useState<Environment>('klm');
  const [htmlTitle, setHtmlTitle] = useState('');

  const domain = window.location.href;

  useEffect(() => {
    const strippedDomain = domain.endsWith('/') ? domain.slice(0, -1) : domain;
    switch (strippedDomain) {
      case `http://localhost:${window.location.port}/trv`:
      case 'https://reisrestrictie-hv.healthyme.travel':
        setEnvironment('transavia');
        setHtmlTitle('HV');
        break;
      default:
        setEnvironment('klm');
        setHtmlTitle('KLM');
    }
  }, [domain]);

  return (
    <>
      <Helmet>
        <title>{htmlTitle} Reisrestrictie</title>
      </Helmet>
      <ChakraProvider resetCSS theme={defaultTheme}>
        <Fonts />
        <Header />
        {environment === 'klm' && <Content />}
        {environment === 'transavia' && <ContentTrv />}

        <Footer />
      </ChakraProvider>
    </>
  );
};
