import {
  Box,
  Button,
  Heading,
  Link,
  Text,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  HStack,
  VStack,
  PinInput,
  PinInputField,
  FormHelperText,
} from '@chakra-ui/react';
import React, {FormEvent, useState, useEffect} from 'react';
import {AlertBox} from '../AlertBox';
import {ContentContainer} from '../ContentContainer';
import {useHandleRestriction} from '../useHandleRestriction';

export const ContentTrv = () => {
  const [endpoint, setEndpoint] = useState('');
  const [showEmployeeInput, setShowEmployeeInput] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [restriction, setRestriction] = useState('');
  const [formIsComplete, setFormIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {isLoading, isCompleted, response, isError} = useHandleRestriction({
    isSubmitting,
    endpoint,
  });
  const employeeNumberLength = 5;

  useEffect(() => {
    setFormIsComplete(
      restriction !== '' && employeeNumber.length === employeeNumberLength,
    );
    if (restriction === 'on') {
      setEndpoint(
        process.env.REACT_APP_API_REGISTER_TRV?.replace(
          '[personeelsNummer]',
          employeeNumber,
        ) as string,
      );
    } else {
      setEndpoint(
        process.env.REACT_APP_API_UNREGISTER_TRV?.replace(
          '[personeelsNummer]',
          employeeNumber,
        ) as string,
      );
    }
  }, [employeeNumber, restriction]);

  useEffect(() => {
    if (isCompleted) {
      setIsSubmitting(false);
    }
  }, [isCompleted]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
  };

  const handleEmployeeNumberChange = (value: string) => {
    setEmployeeNumber(value);
  };

  const handleRestrictionChange = (value: string) => {
    setRestriction(value);
  };

  return (
    <ContentContainer>
      <Heading as="h1" variant="h1">
        Welkom op de Reisrestrictie-portal van KLM Health Services
      </Heading>
      <Text>
        <strong>
          Indien je een reisrestrictie wilt aanvragen voor stops/layovers waar
          een QR-code voor crew verplicht is gesteld op basis van Gevaccineerd
          of Genezen van Covid-19, kun je dat hier doen.
        </strong>
      </Text>
      <Text>
        KHS verwerkt je persoonsgegevens in overeenstemming met de{' '}
        <Link href="https://klmhealthservices.com/privacybeleid/" isExternal>
          privacy policy
        </Link>{' '}
        van KHS. KHS geeft op basis van jouw registratie een reisrestrictie
        (advies) af aan de Tracking & Control afdeling Cabine en Cockpit van
        Transavia.
      </Text>
      <Text>
        Kijk voor meer informatie in de Q&A op de Coronapagina op T-net, of neem
        contact op met je leidinggevende.
      </Text>
      <Text>
        Als je de reisrestrictie wilt intrekken omdat je weer volledig inzetbaar
        bent op alle Transavia-stops/layovers, kun je dat ook op deze portal
        aangeven.
      </Text>

      <Box>
        <Button
          colorScheme="primary"
          onClick={() => setShowEmployeeInput(true)}>
          Geef dit door
        </Button>
      </Box>
      {showEmployeeInput && (
        <Box mt="8" display="inline-block" maxW="container.sm">
          <form onSubmit={handleSubmit}>
            <FormControl as="fieldset">
              <FormLabel as="legend" paddingX="2">
                Geef het door
              </FormLabel>
              <FormControl isRequired mb="4">
                <FormLabel>Ik wil mijn reisrestrictie ...</FormLabel>
                <RadioGroup onChange={handleRestrictionChange}>
                  <VStack spacing="2" align="flex-start">
                    <Radio value="on">... aanmelden</Radio>
                    <Radio value="off">... opheffen</Radio>
                  </VStack>
                  <Text mt="2"></Text>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Personeelsnummer</FormLabel>
                <HStack wrap="wrap">
                  <PinInput
                    value={employeeNumber}
                    onChange={handleEmployeeNumberChange}>
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                  </PinInput>
                </HStack>
              </FormControl>
              <FormControl>
                <Button
                  isLoading={isLoading}
                  mt={4}
                  colorScheme="primary"
                  type="submit"
                  disabled={!formIsComplete}>
                  Akkoord en wijzig reisrestrictie
                </Button>
              </FormControl>
            </FormControl>
            {response.title && response.msg && (
              <AlertBox
                title={response.title}
                description={response.msg}
                isError={isError}
              />
            )}
          </form>
        </Box>
      )}
    </ContentContainer>
  );
};
