import type {SystemStyleObject} from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  textDecoration: 'underline',
  fontWeight: 'bold',
};

export const Link = {
  baseStyle,
};
