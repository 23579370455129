import {
  Box,
  Button,
  Heading,
  Link,
  Text,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  HStack,
  VStack,
  PinInput,
  PinInputField,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import React, {FormEvent, useState, useEffect, ChangeEvent} from 'react';
import {RestrictionTypes} from '../../types';
import {AlertBox} from '../AlertBox';
import {ContentContainer} from '../ContentContainer';
import {useHandleRestriction} from '../useHandleRestriction';
import dayjs from 'dayjs';

export const Content = () => {
  const [endpoint, setEndpoint] = useState('');
  const [showEmployeeInput, setShowEmployeeInput] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [restriction, setRestriction] = useState<
    RestrictionTypes | undefined
  >();
  const [date, setDate] = useState<string | undefined>(undefined);
  const [formIsComplete, setFormIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {isLoading, isCompleted, response, isError} = useHandleRestriction({
    isSubmitting,
    endpoint,
  });
  const employeeNumberLength = 8;
  const today = dayjs(new Date()).format('YYYY-MM-DD');

  useEffect(() => {
    let formIsCompleted = false;
    if (restriction) {
      formIsCompleted =
        restriction !== undefined &&
        employeeNumber.length === employeeNumberLength;
    }
    if (restriction === 'on_startdate') {
      formIsCompleted = formIsCompleted && !!date;
    }
    setFormIsComplete(formIsCompleted);
    if (restriction === 'on') {
      setEndpoint(
        process.env.REACT_APP_API_REGISTER?.replace(
          '[personeelsNummer]',
          employeeNumber,
        ) as string,
      );
    } else if (restriction === 'on_startdate') {
      if (date) {
        const replacedEndpoint =
          process.env.REACT_APP_API_REGISTER_WITH_STARTDATE?.replace(
            '[ingangsdatum]',
            date,
          ).replace('[personeelsNummer]', employeeNumber);
        setEndpoint(replacedEndpoint as string);
      }
    } else {
      setEndpoint(
        process.env.REACT_APP_API_UNREGISTER?.replace(
          '[personeelsNummer]',
          employeeNumber,
        ) as string,
      );
    }
  }, [employeeNumber, restriction, date]);

  useEffect(() => {
    if (isCompleted) {
      setIsSubmitting(false);
    }
  }, [isCompleted]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
  };

  const handleEmployeeNumberChange = (value: string) => {
    setEmployeeNumber(value);
  };

  const handleRestrictionChange = (value: RestrictionTypes) => {
    setDate(undefined);
    setRestriction(value);
  };

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedDate = dayjs(event.currentTarget.value);
    if (formattedDate.isValid()) {
      setDate(formattedDate.format('DD-MM-YYYY'));
    } else {
      setDate(undefined);
    }
  };

  return (
    <ContentContainer>
      <Heading as="h1" variant="h1">
        Welkom op de Reisrestrictie-portal van KLM Health Services
      </Heading>
      <Text>
        <strong>
          Indien je een reisrestrictie wilt aanvragen voor bestemmingen waar een
          Covid-vaccinatie verplicht is gesteld, kun je dat hier doen.
        </strong>
      </Text>
      <Text>
        KHS verwerkt je persoonsgegevens in overeenstemming met de{' '}
        <Link href="https://klmhealthservices.com/privacybeleid/" isExternal>
          privacy policy
        </Link>{' '}
        van KHS. KHS geeft op basis van jouw registratie een reisrestrictie
        (advies) af aan de afdeling Planning & Assignment van KLM of KLC.
      </Text>
      <Text>
        Kijk voor meer informatie op de NewsApp (Q&A), of neem contact op met je
        leidinggevende.{' '}
      </Text>
      <Text>
        Als je de reisrestrictie wilt intrekken omdat je weer volledig inzetbaar
        bent op alle KLM- of KLC-bestemmingen, kun je dat ook op deze portal
        aangeven.
      </Text>

      <Box>
        <Button
          colorScheme="primary"
          onClick={() => setShowEmployeeInput(true)}>
          Geef dit door
        </Button>
      </Box>
      {showEmployeeInput && (
        <Box mt="8" display="inline-block" maxW="container.sm">
          <form onSubmit={handleSubmit}>
            <FormControl as="fieldset">
              <FormLabel as="legend" paddingX="2">
                Geef het door
              </FormLabel>
              <FormControl isRequired mb="4">
                <FormLabel>Ik wil mijn reisrestrictie ...</FormLabel>
                <RadioGroup onChange={handleRestrictionChange}>
                  <VStack spacing="2" align="flex-start">
                    <Radio value="on">... aanmelden per direct</Radio>
                    <Radio value="on_startdate">... aanmelden vanaf</Radio>
                    {restriction === 'on_startdate' && (
                      <Box>
                        <Input
                          type="date"
                          min={today}
                          placeholder="Selecteer datum"
                          onChange={handleDateChange}
                        />
                      </Box>
                    )}
                    <Radio value="off">... opheffen</Radio>
                  </VStack>
                  <Text mt="2"></Text>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Personeelsnummer</FormLabel>
                <FormHelperText>
                  Is het nummer korter dan 8 cijfers? Zet er nullen voor.
                </FormHelperText>
                <FormHelperText mb="3">
                  Voorbeeld: 12345, wordt 00012345
                </FormHelperText>
                <HStack wrap="wrap">
                  <PinInput
                    value={employeeNumber}
                    onChange={handleEmployeeNumberChange}>
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                    <PinInputField required />
                  </PinInput>
                </HStack>
              </FormControl>
              <FormControl>
                <Button
                  isLoading={isLoading}
                  mt={4}
                  colorScheme="primary"
                  type="submit"
                  disabled={!formIsComplete}>
                  Akkoord en wijzig reisrestrictie
                </Button>
              </FormControl>
            </FormControl>
            {response.title && response.msg && (
              <AlertBox
                title={response.title}
                description={response.msg}
                isError={isError}
              />
            )}
          </form>
        </Box>
      )}
    </ContentContainer>
  );
};
