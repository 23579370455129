import {Box, Image} from '@chakra-ui/react';
import React from 'react';
import cross from '../../assets/images/cross.png';

export const Footer = () => {
  return (
    <Box as="footer" bg="#005b83" h="260" position="relative">
      <Image
        src={cross}
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      />
    </Box>
  );
};
