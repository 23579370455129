import {Global} from '@emotion/react';
import React from 'react';
export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Noa LT Std';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/NoaLTStd-Regular.woff');
      }                 
      @font-face {
        font-family: 'Noa LT Std';
        font-weight: 300;
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/NoaLTStd-Light.woff');
      }
      `}
  />
);
