import React, {ReactNode} from 'react';
import {Container} from '@chakra-ui/react';

interface Props {
  children: ReactNode | ReactNode[];
}
export const Main = ({children, ...props}: Props) => {
  return (
    <Container maxW="container.xl" {...props}>
      {children}
    </Container>
  );
};
